import './App.css';
import "bulma/css/bulma.min.css";
import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import TonTransaction from "./components/TonTransaction";
import TonAddress from "./components/TonAddress";

function App() {
    return (
        <div className="App" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh'
        }}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={TonAddress}/>
                    <Route exact path='/transaction' component={TonTransaction}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
