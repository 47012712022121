import {Component} from "react";
import {withRouter} from "react-router-dom";
import TonWeb from "tonweb";
import axios from "axios";
import {BN} from "tonweb/src/utils";

var displayCoins = (coins) => {
  const x = coins / 1e9
  return Math.round(x * 100) / 100
}

const depositMargin = 1e9

class TonTransaction extends Component {
    constructor(props) {
        super();
        this.state = {
            poolAddress: new TonWeb.utils.Address('EQAbk8fAmQ1x6Az7cDXWWm08zjzVL1a1MPNdwqAWoE181Xy4'),
            address: new TonWeb.utils.Address(localStorage.getItem("user_address")),
            balance: new BN(0),
            pendingDeposit: new BN(0),
            pendingWithdrawal: new BN(0),
            isCorrect: true,
            amount: 0,
            url_amount: 0,
            url_comment: 0,
            url: undefined,
            url_reason: undefined,
            a: undefined,
            b: undefined,
            showModal: undefined
        }

        this.tonweb = new TonWeb()
        this.checkAddress = (address) => {
            return this.tonweb.utils.Address.isValid(address)
        }
        this.handleDeposit = this.handleDeposit.bind(this);
        this.handleWithdrawal = this.handleWithdrawal.bind(this);
        this.handleCancelYes = this.handleCancelYes.bind(this);
    }

    async componentDidMount() {
        await Promise.all([this.getBalance(), this.getDeposit()]);
        this.interval = setInterval(async () => {
            await Promise.all([this.getBalance(), this.getDeposit()]);
        }, 7000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async getBalance() {
        try {
            const wc = this.state.address.wc.toString()
            const hash = '0x' + Buffer.from(this.state.address.hashPart).toString('hex').toUpperCase()
            const url = "https://testnet.toncenter.com/api/v2/jsonRPC"

            const resp = await axios.post(url,
                {
                    "method": "runGetMethod",
                    "params": {
                        "address": this.state.poolAddress.toString(),
                        "method": "get_user_shares",
                        "stack": [['num', wc], ['num', hash]]
                    },
                }
            )

            const balanceShare = new BN(resp.data.result.stack[0][1].toString().substr(2), 16)
            const withdrawalShare = new BN(resp.data.result.stack[1][1].toString().substr(2), 16)

            const shareResp = await axios.post(url,
                {
                    "method": "runGetMethod",
                    "params": {
                        "address": this.state.poolAddress.toString(),
                        "method": "get_share_price",
                        "stack": []
                    },
                }
            )
            const a = new BN(shareResp.data.result.stack[0][1].toString().substr(2), 16)
            const b = new BN(shareResp.data.result.stack[1][1].toString().substr(2), 16)

            const newBalance = a.mul(balanceShare).div(b)
            const newPendingWithdrawal = a.mul(withdrawalShare).div(b)

            console.log()
            this.setState({
                balance: newBalance,
                pendingWithdrawal: newPendingWithdrawal,
                a,
                b,
                url: this.state.balance.eq(newBalance) && this.state.pendingWithdrawal.eq(newPendingWithdrawal) ? this.state.url : undefined
            })
        } catch (e) {
            console.log(e)
        }
    }

    async getDeposit() {
        try {
            const wc = this.state.address.wc.toString()
            const hash = '0x' + Buffer.from(this.state.address.hashPart).toString('hex').toUpperCase()
            const url = "https://testnet.toncenter.com/api/v2/jsonRPC"

            const resp = await axios.post(url,
                {
                    "method": "runGetMethod",
                    "params": {
                        "address": this.state.poolAddress.toString(),
                        "method": "get_pending_deposit",
                        "stack": [['num', wc], ['num', hash]]
                    },
                }
            )
            const deposit = new BN(resp.data.result.stack[0][1].toString().substr(2), 16)

            this.setState({
                pendingDeposit: deposit,
                url: this.state.pendingDeposit.eq(deposit) ? this.state.url : undefined
            })
        } catch (e) {
            console.log(e)
        }
    }

    async handleDeposit(e) {
        e.preventDefault()
        if (this.state.amount < 10) {
          this.setState({
              showModal: 'MinDeposit'
          })
          return;
        }

        const X = new BN(parseFloat(this.state.amount) * 1e9)
        const A = X.add(new BN(depositMargin))

        const c = new TonWeb.boc.Cell();
        c.bits.writeUint(0x15db9d39, 32);
        c.bits.writeGrams(X);
        const s2 = c.bits.toString(16)
        const s1 = await c.hash()
        const C = (Buffer.from(s1).toString('hex').substr(-4) + s2).toLowerCase()

        const url = `ton://transfer/${this.state.poolAddress}?amount=${A}&text=${C}`
        this.setState({
          url: url,
          url_amount: A,
          url_comment: C,
          url_reason: "deposit"
        })
    }

    async handleWithdrawal(e) {
        e.preventDefault()
        const X = new BN(parseFloat(this.state.amount) * 1e9)
        const A = new BN(depositMargin)

        const Y = X * this.state.b / this.state.a
        const c = new TonWeb.boc.Cell();
        c.bits.writeUint(0x35de5f9e, 32);
        c.bits.writeUint(0, 1);
        c.bits.writeGrams(Y);
        c.bits.writeUint(0, 3);
        const s2 = c.bits.toString(16)
        const s1 = await c.hash()
        const C = (Buffer.from(s1).toString('hex').substr(-4) + s2).toLowerCase()

        const url = `ton://transfer/${this.state.poolAddress}?amount=${A}&text=${C}`
        this.setState({
          url: url,
          url_amount: A,
          url_comment: C,
          url_reason: "withdrawal",
          showModal: "Withdrawal"
        })
    }

    async handleCancelYes(e) {
        e.preventDefault()
        const A = new BN(depositMargin);

        const c = new TonWeb.boc.Cell();
        c.bits.writeUint(0x731f8168, 32);
        const s2 = c.bits.toString(16);
        const s1 = await c.hash()
        const C = (Buffer.from(s1).toString('hex').substr(-4) + s2).toLowerCase()

        const url = `ton://transfer/${this.state.poolAddress}?amount=${A}&text=${C}`
        this.setState({
          url: url,
          url_amount: A,
          url_comment: C,
          url_reason: "cancel"
        })
    }

    render() {
        return (
            <div style={{
                padding: '0 20px',
                height: '100vh'
            }}>
                <h3 className="ton-title title is-3">TonCats <span style={{"color": "#ff0000"}}>TESTNET</span> nominator pool</h3>
                Lend your coins to a full node owner and earn 10% interest rate as validation rewards.
                <a href="https://t.me/tonstaking" style={{
                    overflowWrap: 'break-word'
                }}> Read more. </a>
                <form onSubmit={(e) => this.handleSubmit(e)} style={{
                    margin: '0 auto',
                    marginTop: '30px',
                    maxWidth: '830px'
                }}>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Pool address</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control">
                                    <input className="pool-address input"
                                           value={this.state.poolAddress}
                                           disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Your address</label>
                        </div>
                        <div className="field-body">
                            <div className="field" style={{
                                flexGrow: '10'
                            }}>
                                <div className="control is-expanded">
                                    <input className="address input"
                                           value={this.state.address}
                                           disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <button className="button is-link" onClick={(e) => {
                                    e.preventDefault()
                                    this.props.history.push({
                                        pathname: '/'
                                    });
                                }}>Change address
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label is-normal" style={{
                            padding: 0
                        }}>
                            <label className="label">Your balance</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control" style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <p className="balance" style={{marginRight: '3px'}}
                                    >{displayCoins(this.state.balance)}</p>
                                    <img src='gem-icon.svg' style={{
                                        width: '18pt',
                                        height: '18pt'
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.pendingDeposit != 0 ?
                        <div className="field is-horizontal" style={{
                            alignItems: 'center'
                        }}>
                            <div className="field-label is-normal" style={{padding: 0}}>
                                <label className="label">Pending deposit</label>
                            </div>
                            <div className="field-body" style={{
                                alignItems: 'center'
                            }}>
                                <div className="control" style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <p className="deposit" style={{marginRight: '3px'}}
                                    >{displayCoins(this.state.pendingDeposit)}</p>
                                </div>
                                <img src='gem-icon.svg' style={{
                                    width: '18pt',
                                    height: '18pt'
                                }}/>
                                <button className="button is-danger" style={{marginLeft: '20px'}}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({
                                                showModal: 'CancelDeposit'
                                            })
                                        }}>Cancel
                                </button>
                            </div>
                        </div> : null
                    }
                    {this.state.pendingWithdrawal != 0 ? <div className="field is-horizontal">
                        <div className="field-label is-normal">
                            <label className="label">Pending withdrawal</label>
                        </div>
                        <div className="field-body" style={{
                            alignItems: 'center'
                        }}>
                            <div className="control" style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <p className="withdrawal" style={{marginRight: '3px'}}
                                >{displayCoins(this.state.pendingWithdrawal)}</p>
                                <img src='gem-icon.svg' style={{
                                    width: '18pt',
                                    height: '18pt'
                                }}/>
                            </div>
                        </div>
                    </div> : null}
                    <div className="field">
                        <label className="label">Amount of TON</label>
                        <div className="control">
                            <input className="amount input"
                                   value={this.state.amount}
                                   onInput={(event => {
                                       this.setState({
                                           amount: event.target.value,
                                           url: undefined
                                       })
                                   })}
                                   style={{
                                       maxWidth: '500px'
                                   }}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            maxWidth: '500px',
                            margin: '0 auto',
                            padding: '20px'
                        }}>
                            <button className="button is-link" onClick={(e) => this.handleDeposit(e)}>Deposit</button>
                            <button className="button is-link" onClick={(e) => this.handleWithdrawal(e)}>Withdraw
                            </button>
                        </div>
                    </div>
                </form>
                {this.state.url !== undefined && this.state.url_reason === "deposit" ? <div>
                    <p>Please send {displayCoins(this.state.url_amount)} Toncoins to the stacking pool smart-contract</p>
                    <a href={this.state.url} style={{
                        overflowWrap: 'break-word'
                    }}> {this.state.url} </a>
                    <br/>
                    <p>A change for {displayCoins(depositMargin)} extra toncoins (minus fees) will be returned to your wallet without delay.</p>
                    <p>Your deposit will be credited at the next validator elections (within 36 hours).</p>
                    <p>You will be able to cancel your pending deposit at any time.</p>
                </div> : null
                }
                {this.state.url !== undefined && this.state.url_reason === "withdrawal" ? <div>
                    <p>Please send {displayCoins(this.state.url_amount)} Toncoins to the stacking pool smart-contract to request withdrawal</p>
                    <a href={this.state.url} style={{
                        overflowWrap: 'break-word'
                    }}>{this.state.url}</a>
                    <br/>
                    <p> A change for {displayCoins(depositMargin)} toncoins (minus fees) will be returned to your wallet</p>
                    <p> Your withdrawal request will be processed after validator stake unfreeze (within 36 hours) </p>
                    <p> Withdrawal is guaranteed by nominator pool smart-contract code to be processed</p>
                </div> : null
                }
                {this.state.url !== undefined && this.state.url_reason === "cancel" ? <div>
                    <p>Please send {displayCoins(this.state.url_amount)} Toncoins to the stacking pool smart-contract to cancel your deposit</p>
                    <a href={this.state.url} style={{
                        overflowWrap: 'break-word'
                    }}>{this.state.url}</a>
                    <br/>
                    <p> A change for {displayCoins(depositMargin)} toncoins (minus fees) will be returned to your wallet along with your deposit</p>
                </div> : null
                }

                <div className={`modal ${this.state.showModal === 'CancelDeposit' ? 'is-active' : ''}`}>
                    <div className="modal-background"></div>
                    <header className="modal-card-head" style={{width: '400px'}}>
                        <p className="modal-card-title">Cancel pending deposit</p>
                    </header>
                    <div className="modal-card" style={{width: '400px'}}>
                        <section className="modal-card-body">
                            Your deposit will be credited at the next validator elections. Are you sure want to cancel?
                        </section>
                        <footer className="modal-card-foot" style={{
                            justifyContent: 'space-around'
                        }}>
                            <button className="button is-success" onClick={async (e) => {
                                await this.handleCancelYes(e)
                                this.setState({
                                    showModal: undefined
                                })
                            }}>Yes
                            </button>
                            <button className="button" onClick={() => {
                                this.setState({
                                    showModal: undefined
                                })
                            }}>No
                            </button>
                        </footer>
                    </div>
                </div>

                <div className={`modal ${this.state.showModal === 'Withdrawal' ? 'is-active' : ''}`}>
                    <div className="modal-background"></div>
                    <header className="modal-card-head" style={{width: '400px'}}>
                        <p className="modal-card-title">Withdrawal</p>
                    </header>
                    <div className="modal-card" style={{width: '400px'}}>
                        <section className="modal-card-body">
                            Your withdrawal request will be processed after stake unfreeze. It may take up to 36 hours.
                        </section>
                        <footer className="modal-card-foot" style={{
                            justifyContent: 'space-around'
                        }}>
                            <button className="button is-link" onClick={() => {
                                this.setState({
                                    showModal: undefined
                                })
                            }}>I understand
                            </button>
                        </footer>
                    </div>
                </div>


                <div className={`modal ${this.state.showModal === 'MinDeposit' ? 'is-active' : ''}`}>
                    <div className="modal-background"></div>
                    <header className="modal-card-head" style={{width: '400px'}}>
                        <p className="modal-card-title">Deposit is too small</p>
                    </header>
                    <div className="modal-card" style={{width: '400px'}}>
                        <section className="modal-card-body">
                            Sorry, minimal deposit is 10 Toncoins.
                        </section>
                        <footer className="modal-card-foot" style={{
                            justifyContent: 'space-around'
                        }}>
                            <button className="button is-link" onClick={() => {
                                this.setState({
                                    showModal: undefined
                                })
                            }}>OK
                            </button>
                        </footer>
                    </div>
                </div>


            </div>
        )
    }
}

export default withRouter(TonTransaction);
