import { withRouter } from "react-router-dom";
import {Component} from "react";
import TonWeb from "tonweb";

class TonAddress extends Component {
    constructor() {
        super();
        this.tonweb = new TonWeb();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkAddress = (address) => {
            return this.tonweb.utils.Address.isValid(address)
        }
        var addr = localStorage.getItem("user_address")
        if (addr === null) {
          addr = ''
        }
        this.state = {
            address: addr,
            isCorrect: this.checkAddress(addr)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.checkAddress(this.state.address)) {
            localStorage.setItem("user_address", this.state.address)
            this.props.history.push({
                pathname: '/transaction'
            });
        }
    }

    render() {
        return (
            <div style={{
                margin: '0 auto'
            }}>
                <h1 className="ton-title title is-1">TON nominator pool</h1>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="field">
                        <label htmlFor="address-input" className="label">Please enter your <span style={{"color": "#ff0000"}}>TESTNET</span> TON address</label>
                        <div className={`control ${(this.state.isCorrect ? '' : 'is-danger')}`} style={{
                            padding: '0 20px'
                        }}>
                            <input className="address-input input" style={{
                                maxWidth: '600px',
                                width: '90vw'
                            }}
                                   value={this.state.address}
                                   onInput={(e) => {
                                       this.setState({
                                           address: e.target.value,
                                           isCorrect: this.checkAddress(e.target.value)
                                       })
                                   }}/>
                        </div>
                        {!this.state.isCorrect && this.state.address.length !== 0 ? <p className="help is-danger">This address is invalid</p> : null}
                    </div>
                    <button
                        type="submit"
                        disabled={!this.state.isCorrect}
                        className="button is-link"
                    >OK
                    </button>
                </form>
            </div>
        )
    }
}

export default withRouter(TonAddress);
